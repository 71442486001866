import { createWebHistory, createRouter } from 'vue-router';
import pathLoader from '../plugins/loader'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: pathLoader('home'),
    },

    /* Components Root */
    {
        path: '/',
        name: 'Header',
        component: pathLoader('./components/header/index.vue'),
    },
    {
        path: '/',
        name: 'Footer',
        component: pathLoader('./components/footer/index.vue'),
    },
    {
        path: '/',
        name: 'Form',
        component: pathLoader('./components/form/index.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: pathLoader('login'),
    },
    {
        path: '/sign',
        name: 'SignUp',
        component: pathLoader('sign'),
    },
    {
        path: '/faq',
        name: 'Faq',
        component: pathLoader('faq'),
    },
    {
        path: '/mention',
        name: 'Mention',
        component: pathLoader('mention'),
    },
    {
        path: '/security',
        name: 'Securite',
        component: pathLoader('security'),
    },
    {
        path: '/shop',
        name: 'Shop',
        component: pathLoader('faq'),
    },
    {
        path: '/formulaire',
        name: 'Formulaire',
        component: pathLoader('formulaire'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
