export default {
  "competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétitions"])},
  "slogan1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPATIBLE AVEC TOUS LES SMARTPHONES.  "])},
  "competitions1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui sommes-nous?"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "signIn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander"])},
  "createCompetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Commencez votre expérience avec Tag'n Go."])},
  "formL1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "formL2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
  "formL3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "formL4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "formButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "worldP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Approchez votre Carte Tag’n Go du smartphone (au dessus d'un iPhone, derrière un Android).  "])},
  "rateP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagnez en temps et en argent avec la suppression des frais inutiles de déplacement et des longues attentes dans les réseaux de distribution."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get an invite"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Tag  and go"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons la conviction que la carte de visite digitale est le futur en Afrique et dans le monde.  "])},
  "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça marche ?"])},
  "howT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Choisissez votre forfait et créer votre compte"])},
  "howP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitez de notre offre exclusive de forfaits de cartes de visite NFC pour faciliter vos échanges professionnels et rendre votre communication plus efficace. Nous avons fait différents forfaits pour vous.Faites le choix en bas selon votre besoin."])},
  "howT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer votre page et procéder au paiement si nécessaire"])},
  "howP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois votre forfait choisi, vous aurez accès à une page de configuration. Cette page vous permet de renseigner vos informations que vous voulez partager à votre réseau."])},
  "howT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous envoyons votre carte sous un délai de 72H maximum"])},
  "howP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dernière étape est de profiter de cette expérience unique que nous avons pensée pour vous. Votre carte vous sera livrée dans un délai de 72h maximum."])},
  "shareSmileT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une carte unique pour un profil personnalisable et modifiable en quelques clics"])},
  "shareSmileT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagez votre profil et vos contacts en un seul mouvement"])},
  "shareSmileP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre interface de personnalisable vous permet de créer votre fiche de contact rapidement avec plusieurs dizaines de modules (informations de contact, réseaux sociaux, fiche entreprise, documents,..)"])},
  "shareSmileP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus qu'une seule carte pour partager vos infomations à vos contacts. Votre téléphone n'a pas la technologie NFC? Alors faites scanner votre QRCode. Vous n'avez pas une connexion internet? Alors utiliser la technologie NFC intégrée dans nos cartes."])},
  "anticipateT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus besoin de plusieurs cartes papier, une seule carte, et participez à sauver la planète"])},
  "anticipateP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce à la carte connectée Tag'ngo, vous n'avez plus besoin de vos cartes papiers et vous économisez des frais d'impression, vos modifications d'informations sont automatiquement prises en compte, et vous suivez vos contacts."])},
  "yourBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have your back"])},
  "worldT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europe to West Africa"])},
  "worldP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send money to over 09 countries in West Africa, Ivory coast, Benin, Senegal, etc."])},
  "rateT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affordable rates"])},
  "rateP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hidden fees policy. We post all fees before you commit. We strive to remain competitive."])},
  "transferT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple tranfer methodes"])},
  "transferP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Although we recommend bank transfers, you can also proceed by credit card. Other means are coming...."])},
  "processT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close to real time processing"])},
  "processP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your loved ones receive the money within hours by the means that suit them, mobile money, bank, etc."])},
  "secureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank level security"])},
  "secureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your money and data are safe. We work with certified banking partners at different levels."])},
  "supportT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 support"])},
  "supportP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our teams remain at your disposal to answer all your questions and to help you step by step during the process."])},
  "partnerT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos partenaires"])},
  "partnerP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous travaillons avec un solide réseau de partenaires afin que vous n'ayez pas à vous soucier de la manière dont vos proches reçoivent leur argent."])},
  "contactT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "followT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
  "aboutT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "aboutP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
  "aboutP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2023 Tag'ngo. All rights reserved"])},
  "powered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by BforProcess SAS,  Dakar, SENEGAL "])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashycoin"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Complet"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télephone"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte ? Créer un compte"])},
  "alreadyLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai déjà un compte. Me connecter"])},
  "initTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initier une transaction"])},
  "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de transaction"])},
  "transactionAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de la transaction (en CashyCoin)"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de Cashycoin"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait de Cashycoin"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
  "successInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Initialisée"])},
  "cashycoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashycoin"])},
  "orangeMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange Money (SN)"])},
  "freeMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Money (SN)"])},
  "waves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waves "])},
  "expresso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-money (SN)"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai"])},
  "want": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre transaction a été initialisée avec succès. Vous recevrez un mail de confirmation avec les détails de la transaction.Merci pour la confiance."])},
  "addressCashycoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse Cashycoin"])},
  "pleaseMakeDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour continuer la transaction veuillez faire un depot sur l'addresse suivante"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deconnexion"])}
}