import { createApp } from 'vue'
import App from './App.vue'
import rooter from '../src/router/index'
import install from './install'
import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import './registerServiceWorker'
import i18n from './i18n'
import vuex from "./store"
import AOS from 'aos'
import 'aos/dist/aos.css'
/* Import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* Import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* Import specific icons */
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import Embed from 'v-video-embed'
import posthogPlugin from "./plugins/posthog"; //import the plugin. 
/* Add icons to the library */
library.add(faArrowUp)



createApp(App)
    .use('vue-cookie-accept-decline', VueCookieAcceptDecline)
    .use(posthogPlugin) //install the plugin
    .use(i18n)
    .use(vuex)
    .use(VueSvgIconPlugin, { tagName: 'icon' })
    .use('font-awesome-icon', FontAwesomeIcon)
    .use(install)
    .use(rooter)
    .use(Embed)
    .use(AOS.init())
    .mount('#app')


